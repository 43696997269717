import React, {useEffect, useState} from 'react'
import './Lock.css'

import { url } from '../Components/url'
import axios from 'axios'
function Lock() {

  const [data, setData] = useState({
    name:"",
    password:""
  })
  const [message, setMessage] = useState("")

  async function submit(){
    await axios.post(`${url}/api/admin/login`, {data}, {withCredentials:true})
                .then(res => {
                  setMessage(res.data.message)
                  if(res.status === 201 || res.status === 200){
                    window.location = '/admin/'
                  }
                })
                .catch(err => {
                  setMessage(err.response.data.message)
                })
  }


  async function check(){
    await axios.get(`${url}/api/check-auth`, {withCredentials:true})
              .then(res => {
                if(res.status === 200){
                  window.location = '/admin/'
                }
              })
  }

  useEffect(() => {
    check()
  })
  return (
    <div className='lock-container'>
        <div className='content'>
            <h3>ادخل <span>كلمة المرور</span></h3>
            <input style={{textAlign:'right', fontFamily:"main"}} type='name' placeholder="أسم المستخدم" value={data.name} onChange={(e) => {
              setData(d => {
                return {...d, name:e.target.value}
              })
            }} />
            <input  style={{textAlign:'right', fontFamily:"main", marginTop:"1rem"}} type='password' placeholder="كلمة المرور..." value={data.password} onChange={(e) => {
              setData(d => {
                return {...d, password:e.target.value}
              })
            }} />
            <button onClick={(e) => {
              e.preventDefault()
              submit()
            }}>أدخال</button>
            <p>{message}</p>
        </div>
    </div>
  )
}

export default Lock