import * as BsIcons from 'react-icons/bs'
import * as FaIcons from 'react-icons/fa'

const navBtnsData = [
    {
        title:"الصفحة الرئيسية",
        icon:<BsIcons.BsHouseDoor />,
        path:'/',
        id:Math.floor(Math.random() * 1000)
    },
    {
        title:"حولنا",
        icon:<BsIcons.BsInfoCircle />,
        path:'/about',
        id:Math.floor(Math.random() * 1000)
    },
    {
        title:"الأقساط المدرسية",
        icon:<BsIcons.BsCoin />,
        path:'/fees',
        id:Math.floor(Math.random() * 1000)
    },
    // {
    //     title:"الكادر التدريسي",
    //     icon:<FaIcons.FaChalkboardTeacher />,
    //     path:'/teachers',
    //     id:Math.floor(Math.random() * 1000)
    // },
    {
        title:"تواصل معنا",
        icon:<BsIcons.BsPhone />,
        path:'/contact',
        id:Math.floor(Math.random() * 1000)
    }
]

export default navBtnsData