import React from 'react'
import './Loading.css'
import MoonLoader from 'react-spinners/MoonLoader'

function Loading() {
  return (
    <div className='loading-container'>
        <MoonLoader className='loading-icon' color='#383292' size={40} />
    </div>
  )
}

export default Loading