import React,{useState, useEffect, lazy, Suspense} from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Footer from './Components/Footer';
import Loading from './Pages/Loading';
import axios from 'axios';
import { url } from './Components/url';
import Lock from './Pages/Lock';


const Home = lazy(() => import("./Pages/Home"))
const AdminUsers = lazy(() => import("./Pages/AdminUsers"))
const AddAdminUser = lazy(() => import("./Pages/AddAdminUser"))
const About = lazy(() => import("./Pages/About"))
const Contact = lazy(() => import("./Pages/Contact"))
const Fees = lazy(() => import("./Pages/Fees"))
// const Teachers = lazy(() => import("./Pages/Teachers"))
const NotFound = lazy(() => import("./Pages/404"))
const StudentEditor = lazy(() => import("./Pages/StudentEditor"))
// const AddTeacher = lazy(() => import("./Pages/AddTeacher"))
const AllStudents = lazy(() => import("./Pages/AllStudents"))
// const AdminTeachers = lazy(() => import("./Pages/AdminTeachers"))
const Register = lazy(() => import("./Pages/Register"))
const Login = lazy(() => import("./Pages/Login"))
const Profile = lazy(() => import("./Pages/Profile"))
const VerifyStudents = lazy(() => import("./Pages/VerifyStudents"))
const StudentFees = lazy(() => import("./Pages/StudentFees"))
const Absence = lazy(() => import("./Pages/Absence"))
function App() {
  const [mode, setMode] = useState(false)
  useEffect(() => {
    if(mode === false){
      document.querySelector(":root").style.setProperty("--primary", "#14313f")
      document.querySelector(":root").style.setProperty("--accent", "#383292")
      document.querySelector(":root").style.setProperty("--background", "#ecf6f7")
      document.querySelector(":root").style.setProperty("--background-secondary", "#edf4f5")
      document.querySelector(":root").style.setProperty("--font-color", "#040404")
      document.querySelector(":root").style.setProperty("--border", "1px solid rgb(233, 233, 233)")
      document.querySelector(":root").style.setProperty("--border-dark", "1px solid rgb(209, 209, 209)")
      document.querySelector(":root").style.setProperty("--shadow", "10px 10px 10px rgba(0, 0, 0, .05)")
    }else {
      document.querySelector(":root").style.setProperty("--primary", "#2b556f")
      document.querySelector(":root").style.setProperty("--accent", "#383292")
      document.querySelector(":root").style.setProperty("--background", "#040b0f")
      document.querySelector(":root").style.setProperty("--background-secondary", "#060f14")
      document.querySelector(":root").style.setProperty("--font-color", "#eeeeee")
      document.querySelector(":root").style.setProperty("--border", "1px solid #0a1a24")
      document.querySelector(":root").style.setProperty("--border-dark", "1px solid #112b3b")
      document.querySelector(":root").style.setProperty("--shadow", "10px 10px 10px rgba(17, 17, 17, 0.3)")
    }
  }, [mode])


  const [isLoading, setIsLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  async function loadToken(){
    await axios.get(`${url}/check-token`, {withCredentials:true})
              .then(res => {
                if(res.status === 200 && res.data.data){
                  setIsLoggedIn(true)
                }
                setIsLoading(false)
              })
              .catch(err => {
                if(err){
                  console.log(err)
                  setIsLoading(false)
                }
              })
  }
  useEffect(() => {
    if(isLoading){
      loadToken()
    }
  })



  return (
    <Router>  
      <div className="App">
      <Suspense fallback={<Loading />}>
        <Navbar changeMode={() => {setMode(!mode)}} modeState={mode} authorizization={isLoggedIn} />
        <Routes>
              <Route path='/' element={<Home/>} />
              <Route path='/about' element={<About/> } />
              <Route path='/fees' element={<Fees/>} />
              <Route path='/contact' element={<Contact/>} />
              {/* <Route path='/teachers' element={<Teachers />} /> */}
              <Route path='*' element={<NotFound />} />
              <Route path='/admin/' element={<AllStudents/>} />
              <Route path='/admin/verify-students' element={<VerifyStudents/>} />
              <Route path='/admin/absence-students' element={<Absence/>} />
              <Route path='/admin/fees' element={<StudentFees/>} />
              <Route path='/admin/users' element={<AdminUsers />} />
              <Route path='/admin/add-user' element={<AddAdminUser />} />
              <Route path='/admin/login' element={<Lock />} />
              {/* <Route path='/admin/teacher' element={<AdminTeachers />} /> */}
              <Route path='admin/students/:studentID' element={<StudentEditor />} />
              <Route path='/register' element={<Register isLoggedIn={isLoggedIn} />} />
              <Route path='/login' element={<Login isLoggedIn={isLoggedIn} />} />
              <Route path='/profile' element={<Profile isLoggedIn={isLoggedIn} />} />
        </Routes>
        <Footer />
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
