import React, {useEffect, useState} from 'react'
import { BsList, BsMoon, BsSun, BsX } from 'react-icons/bs'
import './Navbar.css'
import navBtnsData from './NavBtnsData'
import {Link} from 'react-router-dom'
import { url } from './url'
import axios from 'axios'
function Navbar(props) {

    const [sidebar, setSidebar] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
  useEffect(() => {
    async function loadToken(){
      await axios.get(`${url}/check-token`, {withCredentials:true})
                .then(res => {
                  if(res.status === 200 && res.data.data){
                    setIsLoggedIn(true)
                  }
                })
                .catch(err => {
                  if(err){
                    console.log(err)
                  }
                })
    }
    loadToken()
  })
  return (
    <nav>
        <div className='navbar-container'>
            <Link to='/'>
            <div className='navbar-brand'>
                <img src={require('../Assets/images/logo.webp')} alt='logo'></img>
                <div>
                    <h3>مدارس البروج الأهلية</h3>
                    <h6>AL-BUROOJE PRIVATE SCHOOLS</h6>
                </div>
            </div>
            </Link>
            <div className='navbar-right-side'>
                <div className={sidebar ? 'blend blend-active' : 'blend'}></div>
                <div className={sidebar ? 'navbar-btns-container sidebar-active' : 'navbar-btns-container'}>
                    <ul className='navbar-btns-list'>
                        <li className='close-sidebar-btn' onClick={() => {setSidebar(false)}}><BsX /></li>
                        {
                            navBtnsData.map(btn => {
                                return (
                                    <Link to={btn.path} key={btn.id} className='nav-item'>
                                        <li onClick={() => {setSidebar(false)}}>
                                            <div className='icon'>
                                                {btn.icon}
                                            </div>
                                            <div>
                                                <span>{btn.title}</span>
                                            </div>
                                        </li>
                                    </Link>
                                )
                            })
                        }
                    </ul>
                </div>
                        <div className='side-btns'>
                            <p className='mode-btn' onClick={props.changeMode}>{props.modeState ? <BsSun/> : <BsMoon />}</p>
                            {!isLoggedIn ? (
                                <Link to='/register'><button>أنشاء الحساب</button></Link>
                            ) : (
                                <Link to='/profile'><button>الملف الشخصي</button></Link>
                            )
                            }
                            <BsList className='open-sidebar-btn' onClick={() => {setSidebar(true)}} />
                        </div>
            </div>
        </div>
    </nav>
  )
}

export default Navbar