import React from 'react'
import './Footer.css'

import {Link} from 'react-router-dom'
function Footer() {
    const date = new Date()
    const year = date.getFullYear()
  return (
    <footer>
        <div className='footer-content-container'>
            <div>
                <h3>حساباتنا</h3>
                <ul>
                    <li>
                        <a href='https://www.instagram.com/alburooje_schools/' target='_blank' rel="noreferrer">
                            انستقرام
                        </a>
                    </li>
                    <li>
                        <a href='https://www.facebook.com/Burooje2018' target='_blank' rel="noreferrer">
                            فيسبوك
                        </a>
                    </li>
                </ul>
            </div>
            <div>
                <h3><span>روابط</span> سريعة</h3>
                <ul>
                    <li>
                        <Link to='/'>
                            الصفحة الرئيسية
                        </Link>
                    </li>
                    <li>
                        <Link to='/about'>
                            حولنا
                        </Link>
                    </li>
                    <li>
                        <Link to='/fees'>
                            الاقساط المدرسية
                        </Link>
                    </li>
                    <li>
                        <Link to='/contact'>
                            تواصل معنا
                        </Link>
                    </li>
                    
                </ul>
            </div>
        </div>
        <div className='copyright'>
            <p>&copy; 2018-{year} <a href='https://www.instagram.com/alburooje_schools/' target='_blank' rel="noreferrer">Al-Burooje Schools</a></p>
            <p className='creator'>Code By <a href='https://hussainrafid.com'>Hussain Rafid</a></p>
        </div>
    </footer>
  )
}

export default Footer